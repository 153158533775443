export type ContentNode = {
  type:
  | "title"
  | "image"
  | "paragraph"
  | "heading1"
  | "heading2"
  | "heading3"
  | "video"
  | "orderedList"
  | "unorderedList"
  | "numberList"
  | "squareList"
  | "page"
  | "listItem"
  | "subparagraph"
  | "proTip"
  | "link"
  content?: string;
  children?: ContentNode[];
  indent?: number;
  space?: number;
  bottom?: number;
  position?: string;
};

const SportsBetting101: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "sports_betting_101_title_1",
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_1_desc",
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_2",
      space: 8,
    },
    {
      type: "image",
      content: "/images/oddstypes.png",
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_2_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_1",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_1_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_2",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_2_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_3",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_3_desc_4",
          indent: 13,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_2_sub_4",
      indent: 6.5,
      space: 3,
      bottom: 1
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_1",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_2",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_3",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_4",
          indent: 13,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_2_sub_4_desc_5",
          indent: 13,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_2_desc_2",
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_1_desc",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_2_desc",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_3_desc_sub_title_3",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_3_desc_sub_title_3_desc",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_desc",
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_1",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_1_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_2",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_2_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_3",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_3_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_4",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_4_desc",
      indent: 9,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_4_sub_5",
      indent: 6.5,
      space: 3,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_4_sub_5_desc",
      indent: 9,
    },
    {
      type: "heading2",
      content: "sports_betting_101_title_5",
      space: 8,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_1",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_1_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_1_desc_3",
          indent: 19.5,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_2",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_2_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_3",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_2_desc_4",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_2_desc_5",
      indent: 6.5,
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_3",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_3_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "example",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_1",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_2",
          indent: 19.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_3_desc_3",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "heading3",
      content: "sports_betting_101_title_5_sub_4",
      space: 3,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_4_desc",
      indent: 6.5,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_1",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_1_sub_1",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_2",
          indent: 13,
          space: 1,
        },
      ],
    },
    {
      type: "orderedList",
      children: [
        {
          type: "listItem",
          content: "sports_betting_101_title_5_sub_4_desc_2_sub_1",
          indent: 19.5,
          space: 3,
        },
      ],
    },
    {
      type: "paragraph",
      content: "sports_betting_101_title_5_sub_4_desc_3",
    },
  ],
};

const ArbitrageBetting: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "arbitrage_betting_title_1",
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_1_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_betting_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_2_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_betting_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "arbitrage_betting_title_3_desc",
    },
  ],
};

const PositiveEV: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "positive_ev_betting_title_1",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc_sub",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_1_desc_2",
    },
    {
      type: "heading2",
      content: "positive_ev_betting_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_2_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_betting_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc",
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_1",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_2",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_fomula_3",
      indent: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_betting_title_3_desc_2",
    },
  ],
};

const ArbitrageScreenPt1: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "arbitrage_basics_title",
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_title_desc",
    },
    {
      type: "heading2",
      content: "arbitrage_basics_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_step1_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_basics_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_step2_desc",
      space: 3
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_step2_info",
      space: 2
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.league",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.players",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.gameData",
          indent: 6.5,
          space: 2
        },
      ]
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_step2_info2",
      space: 2
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.betSection",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.oddsWager",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.quick_links",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.ev_indicator",
          indent: 6.5,
          space: 2
        }
      ]
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_step2_info3",
      space: 2
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.wager_amounts",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "arbitrage_basics_step2_items.roi",
          indent: 6.5,
          space: 1
        },
      ]
    },
    {
      type: "heading2",
      content: "arbitrage_basics_conclusion_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_basics_conclusion"
    }
  ],
};

const ArbitrageScreenPt2: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "arbitrage_function_title"
    },
    {
      type: "paragraph",
      content: "arbitrage_function_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_function_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step1_desc",
      space: 3
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step1_desc2",
      space: 3
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step1_desc3",
      space: 3
    },
    {
      type: "heading2",
      content: "arbitrage_function_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step2_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_function_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step3_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_function_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step4_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_function_step5_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_step5_desc"
    },
    {
      type: "heading2",
      content: "arbitrage_function_conclusion_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "arbitrage_function_conclusion"
    }
  ]
};

const PositiveEVScreenPt1: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "positive_ev_screen_basics_title",
    },
    {
      type: "paragraph",
      content: "positive_ev_basics_intro",
    },
    {
      type: "heading2",
      content: "positive_ev_filter_menu_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filter_menu_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_screen_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_screen_desc",
      space: 3,
    },
    {
      type: "paragraph",
      content: "positive_ev_left_side_title",
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_league",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_player_team_names",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_game_data",
          space: 1,
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_middle_title",
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_bets",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_odds",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_quick_links",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_width",
          space: 1,
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "positive_ev_right_side_title",
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "positive_ev_devigging_methods",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_fair_value",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_roi",
          space: 1,
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "positive_ev_suggested_bet_size",
          space: 1,
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "heading2",
      content: "positive_ev_conclusion_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_conclusion_desc",
    },
  ]
};

const PositiveEVScreenPt2: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "positive_ev_function_title"
    },
    {
      type: "paragraph",
      content: "positive_ev_functions_intro",
    },
    {
      type: "heading2",
      content: "positive_ev_filter_menu_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_filter_menu_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_quick_link_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_quick_link_desc",
    },
    {
      type: "heading2",
      content: "positive_ev_devigging_formulas_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_devigging_formulas_desc",
      space: 3,
    },
    {
      type: "paragraph",
      content: "positive_ev_devigging_formulas_example",
      space: 3,
    },
    {
      type: "paragraph",
      content: "positive_ev_devigging_formulas_ev_controls",
      space: 3,
    },
    {
      type: "heading2",
      content: "positive_ev_roi_dropdown_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_roi_dropdown_desc",
      space: 3,
    },
    {
      type: "paragraph",
      content: "positive_ev_roi_dropdown_tutorial",
      space: 3,
    },
    {
      type: "heading2",
      content: "positive_ev_conclusion_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "positive_ev_conclusion_desc",
    },
  ]
};

const OddsScreenPt1: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "odds_screen_pt1_title"
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt1_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_step1_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt1_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_step2_desc"
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_pt1_step2_items.league",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step2_items.bet_market",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step2_items.bet_market_details",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step2_items.time",
          indent: 6.5,
          space: 1
        },
      ]
    },
    {
      type: "heading2",
      content: "odds_screen_pt1_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_step3_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt1_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_step4_desc"
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.teams_player_names",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.game_data",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.best_odds",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.average",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.real_time_graph",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.play_by_play_box_score",
          indent: 6.5,
          space: 1
        },
        {
          type: "listItem",
          content: "odds_screen_pt1_step4_items.sportsbooks_odds",
          indent: 6.5,
          space: 1
        },
      ]
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_step4_conc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt1_conclusion_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt1_conclusion"
    }
  ]
};

const OddsScreenPt2: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "odds_screen_pt2_title"
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt2_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_step1_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt2_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_step2_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt2_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_step3_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt2_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_step4_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_pt2_step5_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_step5_desc"
    },
    {
      type: "heading3",
      content: "odds_screen_history_table",
      indent: 6.5,
      space: 8
    },
    {
      type: "paragraph",
      indent: 6.5,
      content: "odds_screen_history_table_desc"
    },
    {
      type: "heading3",
      indent: 6.5,
      content: "odds_screen_trackbet",
      space: 8
    },
    {
      type: "paragraph",
      indent: 6.5,
      content: "odds_screen_trackbet_desc",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_pt2_sportsbooks_order"
    },
  ]
};

const AdvancedFiltersPt1: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "advanced_filters_pt1_title"
    },
    {
      type: "paragraph",
      content: "advanced_filters_basics_intro",
    },
    {
      type: "heading2",
      content: "advanced_filters_how_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filters_how_desc",
      space: 3,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "advanced_filters_sportsbook",
          indent: 6.5,
          space: 1,
        },
        {
          type: "listItem",
          content: "advanced_filters_bet_market_category",
          indent: 6.5,
          space: 1,
        },
        {
          type: "listItem",
          content: "advanced_filters_specific_bet_market",
          indent: 6.5,
          space: 1,
        },
        {
          type: "listItem",
          content: "advanced_filters_sports",
          indent: 6.5,
          space: 1,
        },
        {
          type: "listItem",
          content: "advanced_filters_leagues",
          indent: 6.5,
          space: 1,
        },
      ],
      space: 2,
    },
    {
      type: "heading2",
      content: "advanced_filters_example_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filters_example_desc",
      space: 3,
    },
    {
      type: "paragraph",
      content: "advanced_filters_important_distinction",
      space: 3,
    },
    {
      type: "paragraph",
      content: "advanced_filters_example_result",
      space: 3,
    },
    {
      type: "heading2",
      content: "advanced_filters_conclusion_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filters_conclusion_desc",
    },
  ]
};

const AdvancedFiltersPt2: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "advanced_filters_pt2_title"
    },
    {
      type: "paragraph",
      content: "advanced_filter_bet_types_intro",
    },
    {
      type: "heading2",
      content: "advanced_filter_editing_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filter_editing_desc",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "advanced_filter_pause_button",
        },
        {
          type: "listItem",
          content: "advanced_filter_edit_button",
        },
        {
          type: "listItem",
          content: "advanced_filter_remove_button",
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "advanced_filter_editing_click_desc",
    },
    {
      type: "heading2",
      content: "advanced_filter_add_bet_types_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filter_add_bet_types_desc",
    },
    {
      type: "paragraph",
      content: "advanced_filter_add_bet_types_example",
    },
    {
      type: "heading2",
      content: "advanced_filter_add_leagues_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filter_add_leagues_desc",
    },
    {
      type: "paragraph",
      content: "advanced_filter_add_leagues_example",
    },
    {
      type: "heading2",
      content: "advanced_filter_conclusion_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "advanced_filter_conclusion_desc",
    },
  ]
};

const AdvancedFiltersPt3: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "advanced_filters_pt3_title"
    },
    {
      type: "paragraph",
      content: "multiple_advanced_filters_intro",
    },
    {
      type: "heading2",
      content: "multiple_advanced_filters_how_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "multiple_advanced_filters_how_desc",
    },
    {
      type: "heading2",
      content: "multiple_advanced_filters_example_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "multiple_advanced_filters_example_desc",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "multiple_advanced_filters_example_result_1",
        },
        {
          type: "listItem",
          content: "multiple_advanced_filters_example_result_2",
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "multiple_advanced_filters_layering_desc",
    },
    {
      type: "heading2",
      content: "multiple_advanced_filters_conclusion_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "multiple_advanced_filters_conclusion_desc",
    },
  ]
};

const Assistant: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "assistant_tool_title"
    },
    {
      type: "paragraph",
      content: "assistant_tool_desc",
      space: 3
    },
    {
      type: "heading2",
      content: "assistant_tool_step1_desc"
    },
    {
      type: "heading2",
      content: "assistant_tool_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "assistant_tool_step2_desc"
    },
    {
      type: "heading2",
      content: "assistant_tool_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "assistant_tool_step3_desc"
    },
    {
      type: "heading2",
      content: "assistant_tool_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "assistant_tool_step4_desc"
    },
    {
      type: "paragraph",
      content: "assistant_tool_step4_desc_2"
    },
  ]
};

const BetTypesFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "bet_types_filters_title"
    },
    {
      type: "paragraph",
      content: "bet_types_filters_desc"
    },
    {
      type: "heading2",
      content: "bet_types_filters_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "bet_types_filters_step1_desc"
    },
    {
      type: "heading2",
      content: "bet_types_filters_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "bet_types_filters_step2_desc"
    },
    {
      type: "heading2",
      content: "bet_types_filters_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "bet_types_filters_step3_desc"
    },
    {
      type: "heading2",
      content: "bet_types_filters_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "bet_types_filters_step4_desc"
    },
  ]
};

const BlockExcludeFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "block_exclude_filters_title"
    },
    {
      type: "paragraph",
      content: "block_exclude_filters_desc"
    },
    {
      type: "heading2",
      content: "block_exclude_filters_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "block_exclude_filters_step1_desc"
    },
    {
      type: "heading2",
      content: "block_exclude_filters_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "block_exclude_filters_step2_desc"
    },
    {
      type: "heading2",
      content: "block_exclude_filters_conclusion_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "block_exclude_filters_conclusion"
    }
  ]
};

const DateTimeFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "date_time_filters_title"
    },
    {
      type: "paragraph",
      content: "date_time_filters_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step1_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step2_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step3_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step4_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step5_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step5_desc"
    },
    {
      type: "heading2",
      content: "date_time_filters_step6_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "date_time_filters_step6_desc"
    },
  ]
};

const LeaguesFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "leagues_filters_title"
    },
    {
      type: "paragraph",
      content: "leagues_filters_desc"
    },
    {
      type: "heading2",
      content: "leagues_filters_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "leagues_filters_step1_desc"
    },
    {
      type: "heading2",
      content: "leagues_filters_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "leagues_filters_step2_desc"
    },
    {
      type: "heading2",
      content: "leagues_filters_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "leagues_filters_step3_desc"
    },
    {
      type: "heading2",
      content: "leagues_filters_step4_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "leagues_filters_step4_desc"
    },
  ]
};

const SportsbooksFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: 'title',
      content: 'sportsbooks_filters_title'
    },
    {
      type: "paragraph",
      content: "sportsbooks_intro",
    },
    {
      type: "heading2",
      content: "sportsbooks_selecting_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sportsbooks_selecting_desc",
    },
    {
      type: "heading2",
      content: "sportsbooks_rating_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sportsbooks_rating_desc",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sportsbooks_rating_green",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "sportsbooks_rating_yellow",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "sportsbooks_rating_red",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "sportsbooks_rating_white",
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "sportsbooks_rating_types",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "sportsbooks_rating_bank",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "sportsbooks_rating_dollar",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "sportsbooks_rating_hand",
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "heading2",
      content: "sportsbooks_advanced_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "sportsbooks_advanced_desc",
      space: 8,
    },
    {
      type: "proTip",
      content: "sportsbooks_pro_tip",
      space: 8,
      bottom: 2,
    },
  ],
};

const OddsScreenFormula: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "odds_screen_formula_title"
    },
    {
      type: "paragraph",
      content: "odds_screen_formula_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_formula_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_formula_step1_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_formula_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_formula_step2_desc"
    },
    {
      type: "heading2",
      content: "odds_screen_formula_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "odds_screen_formula_step3_desc",
      space: 6
    },
    {
      type: "paragraph",
      content: "odds_screen_formula_conclusion"
    }
  ]
};

const OddsScreenControls: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "odds_screen_controls_title"
    },
    {
      type: "paragraph",
      content: "odds_screen_controls_desc",
      space: 3
    },
    {
      type: "heading2",
      content: "active_columns_title",
    },
    {
      type: "paragraph",
      content: "active_columns_intro",
    },

    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "active_columns_hold_desc",
          indent: 6.5,
          space: 2
        },
        {
          type: "listItem",
          content: "active_columns_best_desc",
          indent: 6.5,
          space: 2
        },
        {
          type: "listItem",
          content: "active_columns_average_desc",
          indent: 6.5,
          space: 2
        },
      ],
      space: 2,
    },


    {
      type: "heading2",
      content: "active_columns_average_details_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "active_columns_average_details_desc",
    },
    {
      type: "heading2",
      content: "active_columns_hide_sportsbooks_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "active_columns_hide_sportsbooks_desc",
    },
    {
      type: "heading2",
      content: "active_columns_minimum_wager_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "active_columns_minimum_wager_desc",
    },
    {
      type: "heading2",
      content: "active_columns_sorting_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "active_columns_sorting_desc",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "active_columns_sorting_time",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "active_columns_sorting_hold",
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "heading2",
      content: "active_columns_number_rows_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "active_columns_number_rows_desc",
    },
  ]
};

const ShareFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "share_filters_title"
    },
    {
      type: "paragraph",
      content: "share_filters_desc"
    },
    {
      type: "heading2",
      content: "share_filters_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "share_filters_step1_desc"
    },
    {
      type: "heading2",
      content: "share_filters_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "share_filters_step2_desc"
    },
  ]
};

const RoiDropdown: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "roi_dropdown_title"
    },
    {
      type: "paragraph",
      content: "roi_dropdown_desc"
    },
    {
      type: "heading2",
      content: "roi_dropdown_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "roi_dropdown_step1_desc"
    },
    {
      type: "heading2",
      content: "roi_dropdown_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "roi_dropdown_step2_desc"
    },
    {
      type: "paragraph",
      content: "roi_dropdown_step2_desc2"
    },
    {
      type: "heading2",
      content: "roi_dropdown_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "roi_dropdown_step3_desc"
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "roi_dropdown_step3_item1",
          indent: 6.5,
          space: 3
        },
        {
          type: "listItem",
          content: "roi_dropdown_step3_item2",
          indent: 6.5,
          space: 3
        },
        {
          type: "listItem",
          content: "roi_dropdown_step3_item3",
          indent: 6.5,
          space: 3
        },
        {
          type: "listItem",
          content: "roi_dropdown_step3_item4",
          indent: 6.5,
          space: 3
        },
        {
          type: "listItem",
          content: "roi_dropdown_step3_item5",
          indent: 6.5
        }
      ]
    },
  ]
};

const FilterCarousel: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "quick_filters_intro",
    },
    {
      type: "heading2",
      content: "quick_filters_understanding_separate_pages_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_understanding_separate_pages",
    },
    {
      type: "heading2",
      content: "quick_filters_filter_carousel_default_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_filter_carousel_default",
    },
    {
      type: "heading2",
      content: "quick_filters_creating_new_filter_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_creating_new_filter",
    },
    {
      type: "heading2",
      content: "quick_filters_building_filter_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_building_filter",
    },
    {
      type: "heading2",
      content: "quick_filters_updating_filter_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_updating_filter",
    },
    {
      type: "heading2",
      content: "quick_filters_sharing_filters_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_sharing_filters",
    },
    {
      type: "heading2",
      content: "quick_filters_getting_most_from_filters_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "quick_filters_getting_most_from_filters",
    },

  ],
}



const PositiveEVControls: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "ev_controls_desc",
      space: 3,
    },

    {
      type: "heading2",
      content: "kelly_criterion_title"
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062203068?badge=0&autopause=0&player_id=0&app_id=58479",
    },



    {
      type: "paragraph",
      content: "kelly_criterion_desc"
    },
    {
      type: "paragraph",
      content: "kelly_criterion_step1_desc",
      space: 8,
    },

    {
      type: "heading2",
      content: "ev_controls_bankroll",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062202495?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "paragraph",
      content: "bankroll_step1_desc",
      space: 8,
    },

    {
      type: "heading2",
      content: "ev_controls_activeDeviggings",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062201996?badge=0&autopause=0&player_id=0&app_id=58479",
    },

    {
      type: "paragraph",
      content: "active_devigging_step1_desc"
    },
    {
      type: "paragraph",
      content: "active_devigging_note",
      space: 8,
    },

    {
      type: "heading2",
      content: "ev_controls_formulaCase",
    },

    {
      type: "video",
      content: "https://player.vimeo.com/video/1062203028?badge=0&autopause=0&player_id=0&app_id=58479",
    },

    {
      type: "paragraph",
      content: "formula_case_intro",
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "formula_case_worst",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "formula_case_best",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "formula_case_weighted",
          indent: 6.5,
          space: 3,
        },
      ],
      space: 2,
    },
    {
      type: "heading2",
      content: "sort_ev_by_title",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062203938?badge=0&autopause=0&player_id=0&app_id=58479",
    },


    {
      type: "paragraph",
      content: "sort_ev_by_desc",
      space: 8,
    },
    {
      type: "heading2",
      content: "ev_controls_widthWeight",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062204237?badge=0&autopause=0&player_id=0&app_id=58479",
    },


    {
      type: "paragraph",
      content: "width_weight_intro",
    },
    {
      type: "heading2",
      content: "width_weight_title",
      space: 8,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "width_weight_static",
          space: 3,
        },
        {
          type: "listItem",
          content: "width_weight_dynamic",
        },
      ],
      space: 2,
    },


  ],
};

const EvForumlaCreator: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "ev_formula_creator_description"
    },
    {
      type: "heading2",
      content: "ev_formula_creator_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "ev_formula_creator_step1_desc"
    },
    {
      type: "heading2",
      content: "ev_formula_creator_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "ev_formula_creator_step2_desc"
    },
    {
      type: "paragraph",
      content: "ev_formula_creator_note"
    },
  ]
}

const LowHoldPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "low_holds_title",
    },
    {
      type: "paragraph",
      content: "low_holds_desc",
    },
    {
      type: "heading2",
      content: "low_holds_understanding_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_understanding_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "low_holds_money_movement",
      space: 2,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "low_holds_playthrough_status",
      space: 2,
      indent: 6.5,
    },
    {
      type: "paragraph",
      content: "low_holds_other_reasons",
    },
    {
      type: "heading2",
      content: "low_holds_filter_menu_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_filter_menu_desc",
    },
    {
      type: "heading2",
      content: "low_holds_assistant_advanced_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_assistant_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "low_holds_advanced_desc",
    },
    {
      type: "heading2",
      content: "low_holds_using_page_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "low_holds_using_page_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "low_holds_example_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "low_holds_example_win",
      space: 2,
    },
    {
      type: "paragraph",
      content: "low_holds_example_lose",
    },
  ],
};

const MiddlesPage: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "middles_page_desc",
    },
    {
      type: "heading2",
      content: "middles_page_title_1",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_1_desc",
    },
    {
      type: "heading2",
      content: "middles_page_title_2",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_2_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "middles_page_title_2_list",
      indent: 6.5,
    },
    {
      type: "heading2",
      content: "middles_page_title_3",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_3_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_3_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_3",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_3_list_4",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_4",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_4_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_4_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_4_list_2",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_5",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_5_desc",
      space: 2,
    },
    {
      type: "unorderedList",
      children: [
        {
          type: "listItem",
          content: "middles_page_title_5_list_1",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_5_list_2",
          indent: 6.5,
          space: 3,
        },
        {
          type: "listItem",
          content: "middles_page_title_5_list_3",
          indent: 6.5,
        },
      ],
    },
    {
      type: "heading2",
      content: "middles_page_title_6",
      space: 8,
    },
    {
      type: "paragraph",
      content: "middles_page_title_6_desc",
    },
  ],
};

const FreeBetConverter: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "free_bet_converter_title"
    },
    {
      type: "paragraph",
      content: "free_bet_intro",
    },
    {
      type: "heading2",
      content: "free_bet_filter_menu_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_filter_menu_desc",
    },
    {
      type: "heading2",
      content: "free_bet_understanding_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_understanding_desc",
    },
    {
      type: "heading2",
      content: "free_bet_assistant_advanced_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_assistant_desc",
    },
    {
      type: "paragraph",
      content: "free_bet_advanced_desc",
    },
    {
      type: "heading2",
      content: "free_bet_example_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "free_bet_example_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "free_bet_example_bet_desc",
      space: 2,
    },
    {
      type: "paragraph",
      content: "free_bet_example_hedge_desc",
      space: 2,
    },
    {
      type: "numberList",
      children: [
        {
          type: "listItem",
          content: "free_bet_example_fanduel_win",
          indent: 6.5,
        },
        {
          type: "listItem",
          content: "free_bet_example_mgm_win",
          indent: 6.5,
        },
      ],
      space: 2,
    },
    {
      type: "paragraph",
      content: "free_bet_example_conclusion",
    },
  ]
};

const Setting: ContentNode = {
  type: "page",
  children: [
    {
      type: "title",
      content: "settings_title",
    },
    {
      type: "paragraph",
      content: "settings_intro",
      space: 6
    },
    {
      type: "heading2",
      content: "setting_sitewide_title"
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062461247?badge=0&autopause=0&player_id=0&app_id=58479",
    },

    {
      type: "paragraph",
      content: "setting_sitewide_desc"
    },
    {
      type: "heading2",
      content: "setting_sitewide_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_sitewide_step1_desc"
    },
    {
      type: "heading2",
      content: "setting_sitewide_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_sitewide_step2_desc"
    },
    {
      type: "heading2",
      content: "setting_sitewide_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_sitewide_step3_desc",
      space: 8
    },

    {
      type: "heading2",
      content: "setting_location_title"
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062203159?badge=0&autopause=0&player_id=0&app_id=58479",
    },


    {
      type: "paragraph",
      content: "setting_location_desc"
    },
    {
      type: "heading2",
      content: "setting_location_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_location_step2_desc"
    },
    {
      type: "heading2",
      content: "setting_location_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_location_step3_desc",
      space: 8
    },

    {
      type: "heading2",
      content: "setting_authenticator_title"
    },


    {
      type: "video",
      content: "https://player.vimeo.com/video/1062202430?badge=0&autopause=0&player_id=0&app_id=58479",
    },


    {
      type: "paragraph",
      content: "setting_authenticator_desc"
    },
    {
      type: "heading2",
      content: "setting_authenticator_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_authenticator_step1_desc"
    },
    {
      type: "heading2",
      content: "setting_authenticator_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_authenticator_step2_desc",
      space: 8
    },
    {
      type: "heading2",
      content: "setting_display_title"
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062202757?badge=0&autopause=0&player_id=0&app_id=58479",
    },

    {
      type: "paragraph",
      content: "setting_display_desc"
    },
    {
      type: "heading2",
      content: "setting_display_step1_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_display_step1_desc",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_display_step1_desc2",
    },
    {
      type: "paragraph",
      content: "setting_display_step1_desc3",
    },
    {
      type: "heading2",
      content: "setting_display_step2_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_display_step2_desc"
    },
    {
      type: "heading2",
      content: "setting_display_step3_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "setting_display_step3_desc"
    },
    {
      type: "paragraph",
      content: "setting_display_step3_des2",
      space: 8
    },

    {
      type: "heading2",
      content: "advance_filtering_title"
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1062202818?badge=0&autopause=0&player_id=0&app_id=58479",
    },


    {
      type: "paragraph",
      content: "advance_filtering_desc"
    },
  ],
};

const DeviggingStrategy: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "devigging_strategy_what_desc"
    },
    {
      type: "heading2",
      content: "devigging_strategy_how_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "devigging_strategy_how_desc"
    },
    {
      type: "heading3",
      content: "devigging_strategy_additive_title",
      indent: 6.5,
      space: 3
    },
    {
      type: "paragraph",
      content: "devigging_strategy_additive_desc",
      indent: 6.5
    },
    {
      type: "heading3",
      content: "devigging_strategy_multiplicative_title",
      indent: 6.5,
      space: 3
    },
    {
      type: "paragraph",
      content: "devigging_strategy_multiplicative_desc",
      indent: 6.5
    },
    {
      type: "heading3",
      content: "devigging_strategy_power_title",
      indent: 6.5,
      space: 3
    },
    {
      type: "paragraph",
      content: "devigging_strategy_power_desc",
      indent: 6.5
    },
    {
      type: "heading3",
      content: "devigging_strategy_probit_title",
      indent: 6.5,
      space: 3
    },
    {
      type: "paragraph",
      content: "devigging_strategy_probit_desc",
      indent: 6.5
    },
    {
      type: "heading2",
      content: "devigging_strategy_settings_title",
      space: 8
    },
    {
      type: "paragraph",
      content: "devigging_strategy_settings_desc"
    }
  ]
};

const FormulaStrategy: ContentNode = {
  type: "page",
  children: [
    {
      type: "heading2",
      content: "formula_strategy_what_title",
      space: 6
    },
    {
      type: "paragraph",
      content: "formula_strategy_what_desc"
    },
    {
      type: "heading2",
      content: "formula_strategy_traditional_title",
      space: 6
    },
    {
      type: "paragraph",
      content: "formula_strategy_traditional_desc"
    },
    {
      type: "heading2",
      content: "formula_strategy_custom_title",
      space: 6
    },
    {
      type: "paragraph",
      content: "formula_strategy_custom_desc",
      space: 3
    },
    {
      type: "paragraph",
      content: "formula_strategy_example",
      space: 3
    },
    {
      type: "paragraph",
      content: "formula_strategy_conclusion",
      space: 3
    },
    {
      type: "paragraph",
      content: "formula_strategy_note"
    }
  ]
};

const CommunityHmassbets: ContentNode = {
  type: "page",
  children: [
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064841938?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_ev_formula",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064778501?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_adv_multiple",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064778934?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_bankroll",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064778607?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_ev",
      position: "center",
    },
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064778416?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_live_arb",
      position: "center",
    }, {
      type: "video",
      content: "https://player.vimeo.com/video/1064778467?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      type: "heading2",
      content: "hmassbets_lowHold",
      position: "center",
    },
  ]
};

const HmassbetsAdvMultiple: ContentNode = {
  type: "page",
  children: [
    {
      type: "video",
      content: "https://player.vimeo.com/video/1064778501?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ]
}

const RoiFilters: ContentNode = {
  type: "page",
  children: [
    {
      type: "paragraph",
      content: "roi_intro",
    },
    {
      type: "heading2",
      content: "roi_percent_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "roi_percent_desc",
    },
    {
      type: "heading2",
      content: "odds_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "odds_desc",
    },
    {
      type: "heading2",
      content: "maximum_legs_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "maximum_legs_desc",
    },
    {
      type: "heading2",
      content: "bet_amount_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "bet_amount_desc",
    },
    {
      type: "heading2",
      content: "rounding_values_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "rounding_values_desc",
    },
    {
      type: "heading2",
      content: "max_results_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "max_results_desc",
    },
    {
      type: "heading2",
      content: "block_duplicate_bets_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "block_duplicate_bets_desc",
    },
    {
      type: "heading2",
      content: "minimum_wager_liquidity_title",
      space: 8,
    },
    {
      type: "paragraph",
      content: "minimum_wager_liquidity_desc",
    },

  ]
}

interface ContentData {
  [key: string]: ContentNode;
}

export const contentData: ContentData = {
  sports_betting_101: SportsBetting101,
  arbitrage_betting: ArbitrageBetting,
  positive_ev_betting: PositiveEV,
  arbitrage_screen_pt1: ArbitrageScreenPt1,
  arbitrage_screen_pt2: ArbitrageScreenPt2,
  ev_screen_pt1: PositiveEVScreenPt1,
  ev_screen_pt2: PositiveEVScreenPt2,
  odd_screen_pt1: OddsScreenPt1,
  odd_screen_pt2: OddsScreenPt2,
  advanced_filters_pt1: AdvancedFiltersPt1,
  advanced_filters_pt2: AdvancedFiltersPt2,
  advanced_filters_pt3: AdvancedFiltersPt3,
  assistant: Assistant,
  bettypes_filters: BetTypesFilters,
  blockExclude_filters: BlockExcludeFilters,
  date_time_filters: DateTimeFilters,
  leagues_filters: LeaguesFilters,
  sportsbooks_filters: SportsbooksFilters,
  ev_controls: PositiveEVControls,
  odd_screen_formula: OddsScreenFormula,
  odd_screen_controls: OddsScreenControls,
  share_filters: ShareFilters,
  low_holds_page: LowHoldPage,
  middles_page: MiddlesPage,
  free_bet_converter: FreeBetConverter,
  roi_dropdown: RoiDropdown,
  setting: Setting,
  filter_carousel: FilterCarousel,
  devigging_strategy: DeviggingStrategy,
  formula_strategy: FormulaStrategy,
  community_hmassbets: CommunityHmassbets,
  hmassbets_adv_multiple: HmassbetsAdvMultiple,
  ev_formula_creator: EvForumlaCreator,
  roi_filters: RoiFilters,
};

export const educations = [
  {
    title: "sports_betting_101",
    image: "/images/sportsBetting.jpg",
    description: "education_description_1",
  },
  {
    title: "arbitrage_betting",
    image: "/images/arbitrageBetting.png",
    description: "education_description_2",
  },
  {
    title: "positive_ev_betting",
    image: "/images/learn/evbet.png",
    description: "education_description_3",
  },
];


export const oddsScreen = [
  {
    title: "odd_screen_pt1",
    image: "/images/learn/odd_screen_pt1.png",
    description: "odd_screen_pt1_description",
  },
  {
    title: "odd_screen_pt2",
    image: "/images/learn/odd_screen_pt2.png",
    description: "odd_screen_pt2_description",
  },
  {
    title: "odd_screen_formula",
    image: "/images/learn/odd_screen_formula.png",
    description: "odd_screen_formula_description",
  },
  {
    title: "odd_screen_controls",
    image: "/images/learn/odd_screen_controls.png",
    description: "odd_screen_controls_description"
  }
];

export const arbitrage = [
  {
    title: "arbitrage_screen_pt1",
    image: "/images/learn/arbitrage_screen_pt1.png",
    description: "arbitrage_screen_pt1_description",
  },
  {
    title: "arbitrage_screen_pt2",
    image: "/images/learn/arbitrage_screen_pt2.png",
    description: "arbitrage_screen_pt2_description",
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description",

  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  }
];

export const positiveEv = [
  {
    title: "ev_screen_pt1",
    image: "/images/learn/ev_screen_pt1.png",
    description: "ev_screen_pt1_description",
  },
  {
    title: "ev_screen_pt2",
    image: "/images/learn/ev_screen_pt2.png",
    description: "ev_screen_pt2_description",
  },
  {
    title: "ev_controls",
    image: "/images/learn/ev_controls.png",
    description: "ev_controls_description"
  },
  {
    title: "ev_formula_creator",
    image: "/images/learn/odd_screen_formula.png",
    description: "ev_formula_creator_desc",
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description"
  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  }

];

export const advanced = [
  {
    title: "advanced_filters_pt1",
    image: "/images/learn/advanced_filters_pt1.png",
    description: "advanced_filters_pt1_description",
  },
  {
    title: "advanced_filters_pt2",
    image: "/images/learn/advanced_filters_pt2.png",
    description: "advanced_filters_pt2_description",
  },
  {
    title: "advanced_filters_pt3",
    image: "/images/learn/advanced_filters_pt3.png",
    description: "advanced_filters_pt3_description",
  },
  {
    title: "hmassbets_adv_multiple",
    image: "/images/advancedfilters.png",
    description: "hmassbets_adv_multiple_description",
  }
];

export const middles = [
  {
    title: "middles_page",
    image: "/images/learn/middles_page.png",
    description: "middles_description",
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description"
  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  }
];

export const freeBet = [
  {
    title: "free_bet_converter",
    image: "/images/learn/free_bet_converter.png",
    description: "free_bet_description",
  },
  {
    title: "assistant",
    image: "/images/learn/assistant.png",
    description: "assistant_description"
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description"
  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  }
];

export const lowHolds = [
  {
    title: "low_holds_page",
    image: "/images/learn/low_holds_page.png",
    description: "low_holds_description",
  },
  {
    title: "assistant",
    image: "/images/learn/assistant.png",
    description: "assistant_description"
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description"
  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  }
];

export const settings = [
  {
    title: "setting",
    image: "/images/learn/settings.png",
    description: "setting_description",
  },
];


export const filters = [

  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  },
  {
    title: "sportsbooks_filters",
    image: "/images/learn/sportsbooks_filters.png",
    description: "sportsbooks_filters_description"
  },
  {
    title: "bettypes_filters",
    image: "/images/learn/bettypes_filters.png",
    description: "bettypes_filters_description"
  },
  {
    title: "date_time_filters",
    image: "/images/learn/date_time_filters.png",
    description: "date_time_filters_description"
  },
  {
    title: "leagues_filters",
    image: "/images/learn/leagues_filters.png",
    description: "leagues_filters_description"
  },
  {
    title: "roi_filters",
    image: "/images/learn/roi_filter.png",
    description: "roi_filters_description"
  },
  {
    title: "blockExclude_filters",
    image: "/images/learn/blockExclude_filters.png",
    description: "blockExclude_filters_description"
  },
  {
    title: "odd_screen_formula",
    image: "/images/learn/odd_screen_formula.png",
    description: "odd_screen_formula_description",
  },
  {
    title: "odd_screen_controls",
    image: "/images/learn/odd_screen_controls.png",
    description: "odd_screen_controls_description"
  },
  {
    title: "ev_formula_creator",
    image: "/images/learn/odd_screen_formula.png",
    description: "ev_formula_creator_desc",
  },
  {
    title: "share_filters",
    image: "/images/learn/share_filters.png",
    description: "share_filters_description"
  },


]

export const allContent = [
  {
    title: "sports_betting_101",
    image: "/images/sportsBetting.jpg",
    description: "education_description_1",
  },
  {
    title: "arbitrage_betting",
    image: "/images/arbitrageBetting.png",
    description: "education_description_2",
  },
  {
    title: "positive_ev_betting",
    image: "/images/learn/evbet.png",
    description: "education_description_3",
  },
  {
    title: "odd_screen_pt1",
    image: "/images/learn/odd_screen_pt1.png",
    description: "odd_screen_pt1_description",
  },
  {
    title: "odd_screen_pt2",
    image: "/images/learn/odd_screen_pt2.png",
    description: "odd_screen_pt2_description",
  },
  {
    title: "odd_screen_formula",
    image: "/images/learn/odd_screen_formula.png",
    description: "odd_screen_formula_description",
  },
  {
    title: "odd_screen_controls",
    image: "/images/learn/odd_screen_controls.png",
    description: "odd_screen_controls_description",
  },
  {
    title: "arbitrage_screen_pt1",
    image: "/images/learn/arbitrage_screen_pt1.png",
    description: "arbitrage_screen_pt1_description",
  },
  {
    title: "arbitrage_screen_pt2",
    image: "/images/learn/arbitrage_screen_pt2.png",
    description: "arbitrage_screen_pt2_description",
  },
  {
    title: "roi_dropdown",
    image: "/images/learn/roi_dropdown.png",
    description: "roi_dropdown_description",
  },
  {
    title: "filter_carousel",
    image: "/images/learn/filter_carousel.png",
    description: "filter_carousel_description",
  },
  {
    title: "ev_screen_pt1",
    image: "/images/learn/ev_screen_pt1.png",
    description: "ev_screen_pt1_description",
  },
  {
    title: "ev_screen_pt2",
    image: "/images/learn/ev_screen_pt2.png",
    description: "ev_screen_pt2_description",
  },
  {
    title: "ev_controls",
    image: "/images/learn/ev_controls.png",
    description: "ev_controls_description",
  },
  {
    title: "ev_formula_creator",
    image: "/images/learn/odd_screen_formula.png",
    description: "ev_formula_creator_desc",
  },
  {
    title: "advanced_filters_pt1",
    image: "/images/learn/advanced_filters_pt1.png",
    description: "advanced_filters_pt1_description",
  },
  {
    title: "advanced_filters_pt2",
    image: "/images/learn/advanced_filters_pt2.png",
    description: "advanced_filters_pt2_description",
  },
  {
    title: "advanced_filters_pt3",
    image: "/images/learn/advanced_filters_pt3.png",
    description: "advanced_filters_pt3_description",
  },
  {
    title: "hmassbets_adv_multiple",
    image: "/images/advancedfilters.png",
    description: "hmassbets_adv_multiple_description",
  },
  {
    title: "middles_page",
    image: "/images/learn/middles_page.png",
    description: "middles_description",
  },
  {
    title: "free_bet_converter",
    image: "/images/learn/free_bet_converter.png",
    description: "free_bet_description",
  },
  {
    title: "assistant",
    image: "/images/learn/assistant.png",
    description: "assistant_description",
  },
  {
    title: "low_holds_page",
    image: "/images/learn/low_holds_page.png",
    description: "low_holds_description",
  }, {
    title: "devigging_strategy",
    image: "/images/learn/devigging_strategy.png",
    description: "devigging_strategy_description"
  },
  {
    title: "formula_strategy",
    image: "/images/learn/formula_strategy.png",
    description: "formula_strategy_description"
  },

  {
    title: "share_filters",
    image: "/images/learn/share_filters.png",
    description: "share_filters_description"
  },
  {
    title: "setting",
    image: "/images/learn/settings.png",
    description: "setting_description",
  },
];


export const bettingStrategy = [
  {
    title: "devigging_strategy",
    image: "/images/learn/devigging_strategy.png",
    description: "devigging_strategy_description"
  },
  {
    title: "formula_strategy",
    image: "/images/learn/formula_strategy.png",
    description: "formula_strategy_description"
  }

]

export const community = [
  {
    title: "community_hmassbets",
    link: "https://x.com/hmassbets",
    image: "/images/learn/hmassbets.png",
    description: ""

  }
]



export const contentInfo = [
  {
    title: "arbitrage_screen_pt1",
    image: "https://player.vimeo.com/video/1062202215?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "arbitrage_screen_pt2",
    image: "https://player.vimeo.com/video/1064416518?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "ev_screen_pt1",
    image: "https://player.vimeo.com/video/1062203426?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "ev_screen_pt2",
    image: "https://player.vimeo.com/video/1062203528?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "odd_screen_pt1",
    image: "https://player.vimeo.com/video/1062826843?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "odd_screen_pt2",
    image: "https://player.vimeo.com/video/1062860972?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "advanced_filters_pt1",
    image: "https://player.vimeo.com/video/1062818018?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "advanced_filters_pt2",
    image: "https://player.vimeo.com/video/1062202027?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "advanced_filters_pt3",
    image: "https://player.vimeo.com/video/1062202107?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "hmassbets_adv_multiple",
    image: "",
    type: "video"
  },
  {
    title: "assistant",
    image: "https://player.vimeo.com/video/1062202336?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "bettypes_filters",
    image: "https://player.vimeo.com/video/1062202518?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "blockExclude_filters",
    image: "https://player.vimeo.com/video/1062202544?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "date_time_filters",
    image: "https://player.vimeo.com/video/1062202660?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"

  },
  {
    title: "leagues_filters",
    image: "https://player.vimeo.com/video/1062203104?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"

  },
  {
    title: "sportsbooks_filters",
    image: "https://player.vimeo.com/video/1062203987?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "ev_controls",
    image: "",
    type: "video"
  },
  {
    title: "devigging_strategy",
    image: "",
    type: "video"
  },
  {
    title: "formula_strategy",
    image: "",
    type: "video"
  },
  {
    title: "community_hmassbets",
    link: "https://x.com/hmassbets",
    image: "",
    type: "video"
  },
  {
    title: "free_bet_converter",
    image: "https://player.vimeo.com/video/1066010412??title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "odd_screen_formula",
    image: "https://player.vimeo.com/video/1062203366?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "odd_screen_controls",
    image: "https://player.vimeo.com/video/1062836263?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "share_filters",
    image: "https://player.vimeo.com/video/1062203826?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video"
  },
  {
    title: "low_holds_page",
    image: "https://player.vimeo.com/video/1062203194?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "roi_filters",
    image: "https://player.vimeo.com/video/1065237031?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "middles_page",
    image: "https://player.vimeo.com/video/1064716373?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "roi_dropdown",
    image: "https://player.vimeo.com/video/1062203698?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "setting",
    image: "",
    type: "video",
  },
  {
    title: "filter_carousel",
    image: "https://player.vimeo.com/video/1064410091?badge=0&autopause=0&player_id=0&app_id=58479",
    type: "video"
  },
  {
    title: "sports_betting_101",
    image: "/images/sportsBetting.jpg",
    type: "image",
  },

  {
    title: "arbitrage_betting",
    image: "https://player.vimeo.com/video/1013508106?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",
  },
  {
    title: "positive_ev_betting",
    image: "/images/learn/evbet.png",
    type: "image",
  },
  {
    title: "ev_formula_creator",
    image: "https://player.vimeo.com/video/1062202913?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    type: "video",

  }


];
